import Row from 'react-bootstrap/Row';
import {Col} from 'react-bootstrap';

export default function TaxesIntroduction() {
    return (
        <>
            <div>
                <Row>
                    <Col>
                        {/*<h1>Introduction</h1>*/}
                        <div style={{
                            fontSize: "large"
                        }}>
                            <h1>How to Identify Tax Cryptocurrency Fraud</h1>

                            <p>
                                A situation in which you buy 1,000,000 tokens and only receive
                                800,000 may have different causes, and it is not always fraud.
                                Depending on the context and terms of the transaction, it could
                                be the result of a misunderstanding, a calculation error, fees,
                                etc. However, if you are sure that you have been scammed, it is
                                important to understand how the fraud could have occurred and
                                what it could be.
                            </p>

                            <ul>
                                <li>
                                    <strong>Smart contract scam</strong>: Some smart contracts can
                                    be designed to automatically withhold a portion of tokens in
                                    the form of fees or non-refundable funds. You should carefully
                                    read and analyze the terms of the smart contract before making
                                    a transaction.
                                </li>

                                <li>
                                    <strong>Fake fees</strong>: Scammers may offer a transaction
                                    with certain fees and then take more than was agreed upon.
                                    This could be the result of forged smart contracts or the
                                    actions of scammers.
                                </li>

                                <li>
                                    <strong>Social engineering</strong>: Scammers may trick you by
                                    posing as reputable sellers or projects and convincing you to
                                    make a transaction with them.
                                </li>

                                <li>
                                    <strong>Use of counterfeit tokens</strong>: In some cases,
                                    scammers may offer fake tokens instead of real ones. You will
                                    receive tokens that cannot be exchanged for real money.
                                </li>
                            </ul>

                            <p>To determine if fraud has occurred, it is important to:</p>

                            <ul>
                                <li>
                                    <strong>
                                        Check the terms of the transaction and smart contract
                                    </strong>
                                    .
                                </li>

                                <li>
                                    <strong>Check the reputation of the seller or project</strong>
                                    .
                                </li>

                                <li>
                                    <strong>Check the tokens for authenticity</strong>.
                                </li>

                                <li>
                                    <strong>
                                        Contact customer support or regulatory authorities if you
                                        are sure of fraud
                                    </strong>
                                    .
                                </li>
                            </ul>

                            <p>
                                It is important to learn to identify potential risks and conduct
                                your own research before making financial transactions in the
                                world of cryptocurrencies and tokens.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}
