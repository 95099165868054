import './TaxVictim.css';
import TX_VICTIM_1 from './assets/TX_VICTIM_1.png';
import TX_VICTIM_2 from './assets/TX_VICTIM_2.png';
import TX_VICTIM_3 from './assets/TX_VICTIM_3.png';
import TX_VICTIM_4 from './assets/TX_VICTIM_4.png';
import {Button} from "react-bootstrap";

export default function TaxVictim() {
    return (
        <>
            {/*<h1*/}
            {/*    style={{*/}
            {/*        textAlign: 'center',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    Introduction*/}
            {/*</h1>*/}

            <div className="mb-4">
                <div>
                    <Button variant="info" style={{
                        minWidth: "200px",
                        color:"white"
                    }}
                            href="https://pancakeswap.finance/swap?chain=bscTestnet&inputCurrency=0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814&outputCurrency=0x4876fdeE3e78895Ce7ef40C4788269393d929D64"
                            target="_blank">
                        Pair example
                    </Button>
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={TX_VICTIM_1} alt="IMG_1"/>
                    <br/>
                    1. Let's try to buy 100000000000000000000000 TXT tokens.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={TX_VICTIM_2} alt="IMG_1"/>
                    <br/>
                    2. We look at the details of the transaction. We confirm the
                    transaction.
                </div>
            </div>


            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={TX_VICTIM_3} alt="IMG_1"/>
                    <br/>
                    3. We go to BSC-scan, open the transaction details in the block
                    explorer.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={TX_VICTIM_4} alt="IMG_1"/>
                    <br/>
                    4. We see that 2.5% of the tokens went to an unknown address (the
                    recipient of the commission, if we consider the logic of the contract),
                    and we received only about 97.5%.
                </div>
            </div>
        </>
    );
}
