import { useState } from 'react';
import { ethers } from 'ethers';

import {
  explorerURL,
  FactoryAddress,
  getRandomSalt,
} from '../../honeypot/scammer/DeployHoneypotButton';
import DisplayTokenBalance from '../../honeypot/scammer/DisplayTokenBalance';
import OpenPancakeSwapButton from '../../honeypot/scammer/PancakeSwapButton';
import RP_1 from '../../rugpull/scammer/assets/RP_1.png';
import RP_4 from '../../rugpull/scammer/assets/RP_4.png';
import RP_5 from '../../rugpull/scammer/assets/RP_5.png';
import RP_6 from '../../rugpull/scammer/assets/RP_6.png';
import RP_7 from '../../rugpull/scammer/assets/RP_7.png';
import RP_8 from '../../rugpull/scammer/assets/RP_8.png';
import RP_9 from '../../rugpull/scammer/assets/RP_9.png';
import RP_10 from '../../rugpull/scammer/assets/RP_10.png';
import RP_11 from '../../rugpull/scammer/assets/RP_11.png';
import RP_12 from '../../rugpull/scammer/assets/RP_12.png';
import RP_13 from '../../rugpull/scammer/assets/RP_13.png';
import RP_14 from '../../rugpull/scammer/assets/RP_14.png';
import RP_15 from '../../rugpull/scammer/assets/RP_15.png';
import RP_16 from '../../rugpull/scammer/assets/RP_16.png';
import RP_17 from '../../rugpull/scammer/assets/RP_17.png';
import RP_18 from '../../rugpull/scammer/assets/RP_18.png';
import RP_19 from '../../rugpull/scammer/assets/RP_19.png';
import RP_20 from '../../rugpull/scammer/assets/RP_20.png';
import { PiScamTaxToken__factory } from '../../../dapp/typechain-types/factories/contracts/scam/PiScamTaxToken.sol/PiScamTaxToken__factory';
import { CreateFactory__factory } from '../../../dapp/typechain-types/factories/contracts/CreateFactory__factory';
import { numberWithCommas } from '../../../utils';
import { AMOUNT } from '../../honeypot/Sсammer';

const MINT_AMOUNT = 10n ** 40n * AMOUNT;
export default function TaxScammerSimplified() {
  const [deployedAddress, setDeployedAddress] = useState('');
  const [balance, setBalance] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [tax, setTax] = useState('');

  const deployHandler = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const factory = CreateFactory__factory.connect(FactoryAddress, provider);

      const account = await provider.getSigner();

      const transaction = await factory
        .connect(account)
        .deploy(
          getRandomSalt(),
          PiScamTaxToken__factory.bytecode.concat(
            ethers.AbiCoder.defaultAbiCoder()
              .encode(['address'], [account.address])
              .substring(2)
          )
        );

      const rec = await transaction.wait();

      const deployedContract = rec?.logs[0].address as string;
      setDeployedAddress(deployedContract);
    } catch (e) {
      console.log(e);
    }
  };

  const mintHandler = async () => {
    try {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const factory = PiScamTaxToken__factory.connect(
        deployedAddress,
        provider
      );
      const account = await provider.getSigner();
      const transaction = await factory
        .connect(account)
        .mint(account.address, MINT_AMOUNT);
      const rec = await transaction.wait();

      const userBalance = await factory
        .connect(account)
        .balanceOf(account.address);
      setRecipientAddress('0x6472670D0Df94b854840DbDfA0ce071152879BaD');
      setTax(
        '3.1415926535897932384626433832795028841971693993751058209749445923'
      );
      setBalance(userBalance.toString(10));
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        Introduction
      </h1>
      <div className="Wrapper">
        <div>
          <p>
            To get started, you need to deploy a token. To do this, click the
            button below.
          </p>
          <button onClick={deployHandler}>Deploy token</button>
          <p>Deployed address:</p>
          {deployedAddress && (
            <div>
              <a
                href={`${explorerURL}${deployedAddress}`}
                target="_blank"
              >{`${explorerURL}${deployedAddress}`}</a>
            </div>
          )}
        </div>
      </div>

      <div className="Wrapper">
        <div>
          <p>Now we need to mint some tokens.</p>
          <button onClick={mintHandler}>Mint token</button>
        </div>
      </div>

      <div className="Wrapper">
        <DisplayTokenBalance balance={numberWithCommas(balance)} />
      </div>

      <div className="PCS_link">
        <OpenPancakeSwapButton />
      </div>

      <div
        className="TaxTokenInformation"
        style={{
          textAlign: 'center',
        }}
      >
        <br />
        Token config by default:
        <br />
        Fee receiver address: {recipientAddress}
        <br />
        Fee on transfer: {numberWithCommas(tax)}%
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_1} alt="IMG_1" />
        <br />
        1. Go to the tab "Liquidity"
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_4} alt="IMG_1" />
        <br />
        2. Press the button "Add Liquidity"
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_5} alt="IMG_1" />
        <br />
        3. In the "Choose trade pair" field, select our token pair for which we
        will create liquidity.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_6} alt="IMG_1" />
        <br />
        4. When you click on any of the tokens, a token selection window opens.
        Enter the address of your token. You should see the abbreviated name of
        your token..
        <br />
        Attention! The token does not necessarily have to be called "RPT". It
        can also be called "TXT" or other similar names. The token name
        information can be configured during deployment.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_7} alt="IMG_1" />
        <br />
        5. We select the token that we will use to provide liquidity for our
        token. Currently, tBNB is selected, but we will select BUSD.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_8} alt="IMG_1" />
        <br />
        6. We set the liquidity ratio, which will determine the initial price.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_9} alt="IMG_1" />
        <br />
        7. We set the liquidity ratio, which will determine the initial price.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_10} alt="IMG_1" />
        <br />
        8. We confirm. We click on the link in the upper right corner to
        BSC-scan to view the details of the liquidity creation transaction.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_11} alt="IMG_1" />
        <br />
        9. When liquidity is created for a token pair for the first time, the
        first liquidity provider also pays to have PancakeSwap (Uniswap) deploy
        the token pair contract. It is immediately verified.
        <br />
        We are interested in this contract. To find it, we need to go to the
        "Logs" tab.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_12} alt="IMG_1" />
        <br />
        10. We are interested in the log with index 0. In the "Data" window, we
        can see the "pair" field - this will be the address of the pair contract
        that was created. We open it.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_13} alt="IMG_1" />
        <br />
        11. Now we need to "break" the liquidity ratio. We need to change the
        price without losses in collateral, while also breaking the connection
        between Shares (share of the contributor in liquidity) and Reserve
        (balance of reserves providing the pair).
        <br />
        To do this, you need to copy the address of the pair and send the tokens
        we printed to it.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_14} alt="IMG_1" />
        <br />
        12. We will be sending through MetaMask, so we open MetaMask and first
        add our token to it. We click on "Import tokens" and enter the address
        of our token, in our case it will be the address of the token that we
        printed.о {deployedAddress}
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_15} alt="IMG_1" />
        <br />
        13. We only need to enter the address of our token, the rest of the data
        will be attached automatically. We click the Next button.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_16} alt="IMG_1" />
        <br />
        14. When we add the token, it will look something like this. We click on
        Send.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_17} alt="IMG_1" />
        <br />
        15. Enter a number, for example 10000000000000, and send. Attention! You
        cannot send too much, otherwise the pair contract will overflow! Due to
        overflow, trading on this pair will become impossible.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_18} alt="IMG_1" />
        <br />
        16. We click Confirm and wait for the transaction to be executed.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_19} alt="IMG_1" />
        <br />
        17. Now we return to the pair contract page and go to the Contracts tab.
        We connect the wallet by clicking "Connect to Web3" and search the list
        for the function "Sync", it is under index 8.
        <br />
        We launch this function, confirm the transaction. If you get an error
        that it is impossible to calculate gas, then this means that you sent
        too many tokens in the previous steps. In this case, we need the
        function under index 6 "Skim", we specify our address there and send the
        transaction, after that try to send a smaller amount of tokens to the
        pair contract and call Sync again.
      </div>

      <div className="PancakeTradePage1">
        <img src={RP_20} alt="IMG_1" />
        <br />
        18. After these actions, we return to the exchange page, check if it is
        possible to buy tokens and what the exchange rate will be.
      </div>
    </>
  );
}
