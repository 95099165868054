import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import './index.css';
import '@rainbow-me/rainbowkit/styles.css';
import {getDefaultWallets, RainbowKitProvider} from '@rainbow-me/rainbowkit';
import {configureChains, createConfig, WagmiConfig} from 'wagmi';

import {publicProvider} from 'wagmi/providers/public';
import { bscTestnet } from 'viem/chains';

const {chains, publicClient, webSocketPublicClient} = configureChains(
    [
        bscTestnet,
        ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [bscTestnet] : []),
    ],
    [publicProvider()]
);

const {connectors} = getDefaultWallets({
    appName: 'Scam-Fi',
    projectId: 'c29abd6a43e4649cd97692c4bed74d5b',
    chains,
});

const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
    webSocketPublicClient,
});


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </RainbowKitProvider>
        </WagmiConfig>
    </React.StrictMode>
);
