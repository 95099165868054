import {BrowserProvider, FallbackProvider, JsonRpcProvider, JsonRpcSigner, ethers} from "ethers";
import {useMemo, useState} from "react";
import {CreateFactory__factory, HoneypotToken__factory} from "../../../dapp/typechain-types";
import {PublicClient, useContractRead, useContractWrite, usePublicClient, useWaitForTransaction, useWalletClient } from "wagmi";
import { HttpTransport, WalletClient } from "viem";
import { Button } from "react-bootstrap";


function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}


export const explorerURL = "https://testnet.bscscan.com/address/";

export function getRandomSalt() {
    return "0xdf113268c6689a7fea8325f4719773455a61cf4d15d6e050e7f774fb585b" + getRandomInt(1000, 9999).toString(10);
}

export let deployedToken: string = "_";
export const FactoryAddress = "0xf71b095101425588EA3c118d9655e80Af4D93bae";

export function walletClientToSigner(walletClient: WalletClient) {
    const { account, chain, transport } = walletClient

    // @ts-ignore
    const network = {
// @ts-ignore
        chainId: chain.id,
        // @ts-ignore
        name: chain.name,
        // @ts-ignore
        ensAddress: chain.contracts?.ensRegistry?.address,
    }
    const provider = new BrowserProvider(transport, network)
    // @ts-ignore
    const signer = new JsonRpcSigner(provider, account.address)
    return signer
}

/** Hook to convert a viem Wallet Client to an ethers.js Signer. */

export function UseEthersSigner({ chainId }: { chainId?: number } = {}) {
    const { data: walletClient } = useWalletClient({ chainId })
    return useMemo(
        () => (walletClient ? walletClientToSigner(walletClient) : undefined),
        [walletClient],
    )
}

export function publicClientToProvider(publicClient: PublicClient) {
    const { chain, transport } = publicClient
    const network = {
        chainId: chain.id,
        name: chain.name,
        ensAddress: chain.contracts?.ensRegistry?.address,
    }
    if (transport.type === 'fallback') {
        const providers = (transport.transports as ReturnType<HttpTransport>[]).map(
            ({ value }) => new JsonRpcProvider(value?.url, network),
        )
        if (providers.length === 1) return providers[0]
        return new FallbackProvider(providers)
    }
    return new JsonRpcProvider(transport.url, network)
}

/** Hook to convert a viem Public Client to an ethers.js Provider. */
export function UseEthersProvider({ chainId }: { chainId?: number } = {}) {
    const publicClient = usePublicClient({ chainId })
    return useMemo(() => publicClientToProvider(publicClient), [publicClient])
}



const deployHoneypot = async () => {
    try {
        const provider = UseEthersProvider();
        const factory = CreateFactory__factory.connect(FactoryAddress, provider);

        const account = UseEthersSigner()!;

        const transaction = await factory.connect(account).deploy(getRandomSalt(), HoneypotToken__factory.bytecode.concat(
            ethers.AbiCoder.defaultAbiCoder().encode(["address"], [account.address]).substring(2)));

        const rec = await transaction.wait();

        deployedToken = rec?.logs[0].address as string;
        console.log(deployedToken);

    } catch (e) {
        console.log("signer rejected")
    }
}

export function DeployHoneypotButton() {
    let url: string = "Waiting for deploy...";
    let [tokenAddress, setTokenAddress] = useState('');
    return (<div className="honeypotDeploy">
        {/*<button onClick={deployHoneypot}>Deploy Honeypot</button>*/}
        {tokenAddress === '' ? (
            <Button onClick={() => {
                deployHoneypot().then(() => {
                    setTokenAddress(deployedToken);
                    url = explorerURL + deployedToken;
                })
            }}>Deploy</Button>
        ) : (
            <div className="AddressBlock">
                <p>Token Address: {tokenAddress}</p>
                <p>View on Explorer: </p>
                <a href={url}>{url}</a>
            </div>
        )}
    </div>)
}



