import React from 'react';
import Navigation from '../navigation/Navigation';

function Header() {
  return (
    <div>
      <Navigation />
    </div>
  );
}

export default Header;
