import {Accordion} from 'react-bootstrap';
import {ChatBlock} from './introduction/ChatBlock';
import './Introduction.css';

export default function Introduction() {
    return (
        <div style={{maxWidth: '70%'}}>
            <div>
                <h1 className="mb-4">Introduction</h1>

                <div style={{
                    fontSize: "large"
                }}>
                    <h2>Honeypot scam</h2>

                    <p>
                        Honeypot scam is a type of fraud in smart contracts, often
                        represented by an ERC-20 token that can be bought but cannot be
                        sold.
                    </p>

                    <p>
                        The victim sees an offer for a "token that will make x's", goes to a
                        link to a decentralized exchange (in the BSC network: PancakeSwap,
                        Uniswap, etc.), sees that the price of the token is ONLY rising,
                        buys some amount (usually buys a very large amount that is
                        multiplied by more than 10^18).
                    </p>

                    <p>
                        Usually, the number of tokens on the victim's address balance does
                        not even fit in the wallet window. After the victim thinks that they
                        have "made money", they try to sell the token, but it does not work,
                        because the contract at the programming level prohibits the sale of
                        the token.
                    </p>

                    <p>This can be implemented in several ways:</p>

                    <ul>
                        <li>
                            The contract can simply have a function that allows users to buy
                            tokens, but no function that allows users to sell tokens.
                        </li>
                        <li>
                            The contract can have a function that allows users to sell tokens,
                            but the function can only be called by the contract's owner.
                        </li>
                        <li>
                            The contract can have a function that allows users to sell tokens,
                            but the function can only be called if the user meets certain
                            conditions, such as having a certain amount of tokens or having
                            held the tokens for a certain period of time.
                        </li>
                    </ul>

                    <p>
                        Honeypot scams are a common type of fraud in the cryptocurrency
                        industry. Investors can protect themselves from honeypot scams by
                        being aware of the risks and following best practices, such as:
                    </p>

                    <ul>
                        <li>Do your research before investing in any new token.</li>
                        <li>Beware of promises that seem too good to be true.</li>
                        <li>Use only reputable and well-known exchanges.</li>
                        <li>Never give out your private keys or seed phrases to anyone.</li>
                    </ul>

                    <p>Additional notes:</p>

                    <ul>
                        <li>
                            The term "honeypot" is a metaphor for a trap that is designed to
                            attract and capture victims.
                        </li>
                        <li>
                            Honeypot scams are a type of exit scam, where the scammers lure
                            investors in with promises of high returns and then abscond with
                            their funds.
                        </li>
                        <li>
                            Honeypot scams can be difficult to detect, as they often appear to
                            be legitimate projects. However, there are some red flags that
                            investors can look for, such as: * The project is new and has no
                            track record. * The project promises unrealistic returns. * The
                            project uses aggressive marketing tactics. * The project's website
                            or social media presence is poorly maintained.
                        </li>
                    </ul>
                </div>
            </div>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Additional explanation</Accordion.Header>
                    <Accordion.Body>
                        <ChatBlock/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
}
