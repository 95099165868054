/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import {
  Contract,
  ContractFactory,
  ContractTransactionResponse,
  Interface,
} from "ethers";
import type { Signer, ContractDeployTransaction, ContractRunner } from "ethers";
import type { NonPayableOverrides } from "../../common";
import type {
  CreateFactory,
  CreateFactoryInterface,
} from "../../contracts/CreateFactory";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    name: "Deploy",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "salt",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "bytecodeHash",
        type: "bytes32",
      },
    ],
    name: "computeAddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "salt",
        type: "bytes32",
      },
      {
        internalType: "bytes",
        name: "bytecode",
        type: "bytes",
      },
    ],
    name: "deploy",
    outputs: [
      {
        internalType: "address",
        name: "addr",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
] as const;

const _bytecode =
  "0x608060405234801561001057600080fd5b50610340806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c8063481286e61461003b578063cdcb760a1461006a575b600080fd5b61004e610049366004610217565b61007d565b6040516001600160a01b03909116815260200160405180910390f35b61004e61007836600461024f565b610091565b600061008a8383306100e4565b9392505050565b600061009f6000848461010e565b6040516001600160a01b03821681529091507f55ea6c6b31543d8e2ec6a72f71a79c0f4b72ed0d4757172b043d8f4f4cd848489060200160405180910390a192915050565b6000604051836040820152846020820152828152600b8101905060ff815360559020949350505050565b6000834710156101655760405162461bcd60e51b815260206004820152601d60248201527f437265617465323a20696e73756666696369656e742062616c616e636500000060448201526064015b60405180910390fd5b81516000036101b65760405162461bcd60e51b815260206004820181905260248201527f437265617465323a2062797465636f6465206c656e677468206973207a65726f604482015260640161015c565b8282516020840186f590506001600160a01b03811661008a5760405162461bcd60e51b815260206004820152601960248201527f437265617465323a204661696c6564206f6e206465706c6f7900000000000000604482015260640161015c565b6000806040838503121561022a57600080fd5b50508035926020909101359150565b634e487b7160e01b600052604160045260246000fd5b6000806040838503121561026257600080fd5b82359150602083013567ffffffffffffffff8082111561028157600080fd5b818501915085601f83011261029557600080fd5b8135818111156102a7576102a7610239565b604051601f8201601f19908116603f011681019083821181831017156102cf576102cf610239565b816040528281528860208487010111156102e857600080fd5b826020860160208301376000602084830101528095505050505050925092905056fea2646970667358221220a4e8e80dc749ed2630daacbaa7c18b96a7abf5140b6783b2fca9d3bb353dfcda64736f6c63430008130033";

type CreateFactoryConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: CreateFactoryConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class CreateFactory__factory extends ContractFactory {
  constructor(...args: CreateFactoryConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override getDeployTransaction(
    overrides?: NonPayableOverrides & { from?: string }
  ): Promise<ContractDeployTransaction> {
    return super.getDeployTransaction(overrides || {});
  }
  override deploy(overrides?: NonPayableOverrides & { from?: string }) {
    return super.deploy(overrides || {}) as Promise<
      CreateFactory & {
        deploymentTransaction(): ContractTransactionResponse;
      }
    >;
  }
  override connect(runner: ContractRunner | null): CreateFactory__factory {
    return super.connect(runner) as CreateFactory__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): CreateFactoryInterface {
    return new Interface(_abi) as CreateFactoryInterface;
  }
  static connect(
    address: string,
    runner?: ContractRunner | null
  ): CreateFactory {
    return new Contract(address, _abi, runner) as unknown as CreateFactory;
  }
}
