import { NavLink, useLocation } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import classNames from 'classnames';

import LOGO from './assets/LOGO.png';
import './Navigation.css';
import { ConnectButton } from '@rainbow-me/rainbowkit';

export default function Navigation() {
  const location = useLocation();

  const honeypotClassName = classNames({
    'nav-link': true,
    'me-4': true,
    active: location.pathname.startsWith('/honeypot'),
  });

  const taxesClassName = classNames({
    'nav-link': true,
    'me-4': true,
    active: location.pathname.startsWith('/taxes'),
  });

  const rugpullClassName = classNames({
    'nav-link': true,
    'me-4': true,
    active: location.pathname.startsWith('/rugpull'),
  });

  return (
    <Navbar expand="lg" className="w100">
      <Container fluid>
        <NavLink
          className="navbar-brand"
          to="/"
          style={{ marginRight: '30px' }}
        >
          <Image
            src={LOGO}
            style={{
              width: '160px',
            }}
          ></Image>
        </NavLink>

        <div className="navigation-wrapper">
          <Nav>
            <Nav.Item>
              <NavLink
                className={honeypotClassName}
                to="/honeypot/introduction"
              >
                Honeypot
              </NavLink>
            </Nav.Item>

            <Nav.Item>
              <NavLink className={taxesClassName} to="/taxes/introduction">
                Taxes
              </NavLink>
            </Nav.Item>

            <Nav.Item>
              <NavLink className={rugpullClassName} to="/rugpull/introduction">
                Rugpull
              </NavLink>
            </Nav.Item>
          </Nav>

          {/*<Metamask />*/}
          <ConnectButton/>
        </div>
      </Container>
    </Navbar>
  );
}
