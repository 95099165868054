import {useState} from 'react';
import {ethers} from 'ethers';
import {
    CreateFactory__factory,
    RugpullToken__factory,
} from '../../../dapp/typechain-types';
import {
    explorerURL,
    FactoryAddress,
    getRandomSalt,
} from '../../honeypot/scammer/DeployHoneypotButton';
import DisplayTokenBalance from '../../honeypot/scammer/DisplayTokenBalance';
import OpenPancakeSwapButton from '../../honeypot/scammer/PancakeSwapButton';
import './RugpullScammer.css';

import RP_1 from './assets/RP_1.png';
import RP_4 from './assets/RP_4.png';
import RP_5 from './assets/RP_5.png';
import RP_6 from './assets/RP_6.png';
import RP_7 from './assets/RP_7.png';
import RP_8 from './assets/RP_8.png';
import RP_9 from './assets/RP_9.png';
import RP_10 from './assets/RP_10.png';
import RP_11 from './assets/RP_11.png';
import RP_12 from './assets/RP_12.png';
import RP_13 from './assets/RP_13.png';
import RP_14 from './assets/RP_14.png';
import RP_15 from './assets/RP_15.png';
import RP_16 from './assets/RP_16.png';
import RP_17 from './assets/RP_17.png';
import RP_18 from './assets/RP_18.png';
import RP_19 from './assets/RP_19.png';
import RP_20 from './assets/RP_20.png';
import RP_21 from './assets/RP_21.png';
import RP_22 from './assets/RP_22.png';
import RP_23 from './assets/RP_23.png';
import RP_24 from './assets/RP_24.png';
import {Button} from 'react-bootstrap';
import {AMOUNT} from '../../honeypot/Sсammer';
import {useAccount, useContractWrite, useWaitForTransaction} from 'wagmi';
import {readContract, waitForTransaction, writeContract} from '@wagmi/core';

export default function RugpullScammer() {
    const [balance, setBalance] = useState('');
    const [deployedAddress, setDeployedAddress] = useState('');
    const {address: userAddress} = useAccount();

    const {data, isLoading, write} = useContractWrite({
        address: FactoryAddress,
        functionName: "deploy",
        abi: CreateFactory__factory.abi,
        args: [getRandomSalt() as `0x${string}`, RugpullToken__factory.bytecode.concat(
            ethers.AbiCoder.defaultAbiCoder().encode(["address"], [userAddress ? userAddress : ethers.ZeroAddress]).substring(2)) as `0x${string}`]
    });

    const {isSuccess: IS} = useWaitForTransaction({
        hash: data?.hash,
        onSuccess(data) {
            setDeployedAddress(data.logs[0].address)
        }
    })


    const mintHandler = async () => {
        try {
            const {hash} = await writeContract({
                address: deployedAddress as `0x${string}`,
                abi: RugpullToken__factory.abi,
                functionName: "mint",
                args: [userAddress as `0x${string}`, AMOUNT],
            });

            await waitForTransaction({
                hash,
            }).then(async () => {
                const rawBalance = await readContract({
                    address: deployedAddress as `0x${string}`,
                    abi: RugpullToken__factory.abi,
                    functionName: "balanceOf",
                    args: [userAddress as `0x${string}`],
                });
                console.log("balance", rawBalance);
                setBalance(rawBalance.toString(10));
            });
        } catch (e) {
            console.error(e);
        }
    }


    return (
        <>
            <h1
                style={{
                    textAlign: 'center',
                }}
            >
                Scammer
            </h1>
            <div className="Wrapper">
                <div>
                    <p>
                        To start, you need to deploy the token. To do this, click the button
                        below.
                    </p>
                    <>
                        <Button

                            type="button"
                            variant="danger"
                            style={{minWidth: '200px'}}
                            onClick={(e) => {
                                write();
                            }}>
                            {isLoading ? "Wallet loading..." : "Deploy"}
                        </Button>

                        {IS && deployedAddress !== '' && (
                            <div>
                                <p>Successfully deployed!</p>
                                <p>Address: {deployedAddress}</p>
                                <div>
                                    <a href={`${explorerURL}${deployedAddress}`} target="_blank">Look at the blockhain
                                        scan</a>
                                </div>
                            </div>
                        )}</>
                </div>
            </div>

            <div className="Wrapper">
                <div>
                    <p>Now need to mint some tokens.</p>
                    <Button variant="danger" onClick={mintHandler}>
                        Mint token
                    </Button>
                </div>
            </div>

            <div className="Wrapper">
                <DisplayTokenBalance balance={balance}/>
            </div>

            <div className="mb-4">
                <OpenPancakeSwapButton/>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_1} alt="IMG_1"/>
                    <br/>
                    1. Go to the tab "Liquidity"
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_4} alt="IMG_1"/>
                    <br/>
                    2. Press the button "Add Liquidity"
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_5} alt="IMG_1"/>
                    <br/>
                    3. In the "Choose trade pair" field, select the token pair for which you
                    will create liquidity.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_6} alt="IMG_1"/>
                    <br/>
                    4. Click on any of the tokens, a token selection window will open, enter
                    the address of your token, and you should see the abbreviated name of
                    your token.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_7} alt="IMG_1"/>
                    <br/>
                    5. We select the token that we will provide our token liquidity with,
                    currently tBNB is selected, but we will select BUSD.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_8} alt="IMG_1"/>
                    <br/>
                    6. We set the liquidity ratio, this will determine the initial price.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_9} alt="IMG_1"/>
                    <br/>
                    7. We set the liquidity ratio, this will determine the initial price.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_10} alt="IMG_1"/>
                    <br/>
                    8. We confirm. We click on the link in the upper right corner of
                    BSC-scan to see the details of the liquidity creation transaction.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_11} alt="IMG_1"/>
                    <br/>
                    9. When liquidity is created for a token pair for the first time, the
                    first liquidity provider also pays for PancakeSwap(Uniswap) to deploy
                    the token pair contract. It is immediately verified. We are interested
                    in this contract: in order to find it, we need to go to the "Logs" tab.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_12} alt="IMG_1"/>
                    <br/>
                    10. We are interested in the log with index 0. In the "Data" window, we
                    can see the "pair" field - this will be the address of the pair contract
                    that was created. We open it.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_13} alt="IMG_1"/>
                    <br/>
                    11. Now we need to "break" the liquidity ratio. We need to change the
                    price without losses in collateral, while also breaking the connection
                    between Shares (share of the contributor in liquidity) and Reserve
                    (balance of reserves providing the pair).
                    <br/>
                    To do this, you need to copy the address of the pair and send the tokens
                    we printed to it.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_14} alt="IMG_1" style={{
                        maxWidth: "350px"
                    }}/>
                    <br/>
                    12. We will be sending through MetaMask, so we open MetaMask and first
                    add our token to it. We click on "Import tokens" and enter the address
                    of our token, in our case it will be the address of the token that we
                    printed.о {deployedAddress}
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_15} alt="IMG_1" style={{
                        maxWidth: "350px"
                    }}/>
                    <br/>
                    13. We only need to enter the address of our token, the rest of the data
                    will be attached automatically. We click the Next button.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_16} alt="IMG_1"/>
                    <br/>
                    14. When we add the token, it will look something like this. We click on
                    Send.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_17} alt="IMG_1"/>
                    <br/>
                    15. Enter a number, for example 10000000000000, and send. Attention! You
                    cannot send too much, otherwise the pair contract will overflow! Due to
                    overflow, trading on this pair will become impossible.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_18} alt="IMG_1"/>
                    <br/>
                    16. We click Confirm and wait for the transaction to be executed.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_19} alt="IMG_1"/>
                    <br/>
                    17. Now we return to the pair contract page and go to the Contracts tab.
                    We connect the wallet by clicking "Connect to Web3" and search the list
                    for the function "Sync", it is under index 8.
                    <br/>
                    We launch this function, confirm the transaction. If you get an error
                    that it is impossible to calculate gas, then this means that you sent
                    too many tokens in the previous steps. In this case, we need the
                    function under index 6 "Skim", we specify our address there and send the
                    transaction, after that try to send a smaller amount of tokens to the
                    pair contract and call Sync again.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_20} alt="IMG_1"/>
                    <br/>
                    18. After these actions, we return to the exchange page, check if it is
                    possible to buy tokens and what the exchange rate will be.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_21} alt="IMG_1"/>
                    <br/>
                    19. After our victims have bought in, we need to do a Rug pull or
                    withdraw liquidity. To do this, we go to the Liquidity tab, click on the
                    button with the liquidity of our token (HPT in this case).
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_22} alt="IMG_1"/>
                    <br/>
                    20. A window with liquidity information opens. We click Remove.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_23} alt="IMG_1"/>
                    <br/>
                    21. We click Confirm, wait for the transaction to be executed.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_24} alt="IMG_1"/>
                    <br/>
                    22. The amount of tokens A and B that we will receive is displayed. We
                    click Confirm, wait for the transaction to be executed. This is where
                    the scammer's scenario ends.
                </div>
            </div>
        </>
    );
}
