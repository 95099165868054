import "./RugpullVictim.css";
import Pancake_buy_1 from '../../honeypot/victim/assets/Pancake_buy_1.png';
import Pancake_buy_2 from '../../honeypot/victim/assets/Pancake_buy_2.png';
import RP_3 from '../scammer/assets/RP_3.png';
import RP_20 from '../scammer/assets/RP_20.png';
import RP_V5 from '../scammer/assets/RP_V5.png';
import RP_V6 from '../scammer/assets/RP_V6.png';

export default function RugpullVictim() {
    return (
        <>
            <div style={{
                fontSize:"larger"
            }}>
                <h1
                >
                    Victim
                </h1>
                <div style={{
                    maxWidth:"800px"
                }}>
                    <div style={{textAlign: 'left'}}>
                        From the victim's perspective, a rug pull looks like this: the victim
                        receives an address to exchange an unknown token. With an offer to
                        invest in a "promising" project.
                        <br/>
                        For example:
                        <a
                            href="https://pancakeswap.finance/swap?chain=bscTestnet&inputCurrency=0x867F24fe5D75b67224F366DadC2206217c5E2E40&outputCurrency=0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814"
                            target="_blank"
                        >
                            <p>Rugpull example at Pancakeswap</p>
                        </a>
                        <br/>
                        Users are generally attracted to the opportunity to buy coins
                        immediately, because the more coins immediately rise in price, the
                        more they think they will earn.
                        <br/>
                        Let's consider an example of when a user was provided with a coin
                        address.
                        <br/>
                        Example: 0x867F24fe5D75b67224F366DadC2206217c5E2E40
                    </div>
                </div>
            </div>
            <br/>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_1} alt="Pancake_buy_1"/>
                    <br/>
                    1. To buy a "custom" token, you need to select the token you will buy
                    for from the top, and the token you will buy from the bottom.
                </div>
            </div>

            <br/>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_2} alt="Pancake_buy_2"/>
                    <br/>
                    2. In the search, we insert the address of the custom token.
                </div>
            </div>

            <br/>
            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_3} alt="Pancake_buy_3"/>
                    <br/>
                    3. We enter the amount of tokens we are willing to spend on the custom
                    token.
                </div>
            </div>

            <br/>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_20} alt="Pancake_buy_4"/>
                    <br/>
                    4. After entering the required parameters, we click "Swap" and confirm
                    the transaction.
                </div>
            </div>


            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_V5} alt="Pancake_buy_4"/>
                    <br/>
                    5. When the scammer makes a rug pull, we will see the following message:
                    "Insufficient liquidity for this trade."
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RP_V6} alt="Pancake_buy_4"/>
                    <br/>
                    6. In some cases, if the scammer withdraws liquidity, but there are many
                    tokens and little collateral left on the pair contract, we will simply
                    see a very low exchange rate.
                </div>
            </div>
        </>
    );
}
