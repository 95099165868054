import './TaxVictim.css';
import TAX_EXAMPLE from './assets/TAX_EXAMPLE.png';
import TRANSFER_EXAMPLE from './assets/TRANSFER_EXAMPLE.png';
import RESULT_EXAMPLE from './assets/RESULT_EXAMPLE.png';
import {Button, Col} from 'react-bootstrap';


export default function TaxVictimSimplified() {
    return (
        <>
            <div className="mb-4">
                <h1>Introduction</h1>
                <p>
                    The scam tokens with commissions are designed to confuse the user about
                    their balance during transfers so that they do not understand how many
                    tokens they are losing. To do this, the balance is not displayed in
                    MetaMask, and it is also difficult to estimate the amount by which the
                    balance has changed.
                </p>
                <div className="mb-4">
                    <div>
                        <Button
                            href="https://pancakeswap.finance/swap?chain=bscTestnet&inputCurrency=0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814&outputCurrency=0x4876fdeE3e78895Ce7ef40C4788269393d929D64"
                            target="_blank"
                            style={{
                                minWidth: "200px"
                            }}>
                            Pair example
                        </Button>
                    </div>
                </div>

                <div className="mb-4">
                    <div className="section-wrapper">
                        <img className="section-image" src={TAX_EXAMPLE} alt="IMG_1" style={{
                            maxHeight: "500px"
                        }}/>
                        <br/>
                        The scam, the problem with this token is that the balance calculation in
                        this smart contract is intentionally broken, that's why it is very large
                        in size: so that the user cannot visually estimate the balance of
                        tokens, and it does not fit in the wallet. Also, a feature of this type
                        of scam is that the calculation of values during transfers is
                        intentionally confused, so that the user always has a number of tokens
                        from different digits, thus it is psychologically difficult to estimate
                        the spent tokens.
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={TRANSFER_EXAMPLE} alt="IMG_1" style={{
                        maxHeight: "500px"
                    }}/>
                    <br/>
                    At what seems to be a transfer of 10000000000000000000000, let's see:
                    how many did the recipient actually receive.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={RESULT_EXAMPLE} alt="IMG_1"/>
                    <br/>
                    As we can see, the user sent a round and readable number, but the
                    recipient received a smaller amount consisting of very different
                    numbers, which makes it difficult to read.
                </div>
            </div>
        </>
    );
}
