import { Route, Routes } from 'react-router-dom';

import Layout from './layout/Layout';

import Home from './pages/Home';
import Introduction from './pages/honeypot/Introduction';
import Scammer from './pages/honeypot/Sсammer';
import Victim from './pages/honeypot/Victim';
import RugpullIntroduction from './pages/rugpull/introduction/RugpullIntroduction';
import RugpullVictim from './pages/rugpull/victim/RugpullVictim';
import RugpullScammer from './pages/rugpull/scammer/RugpullScammer';
import TaxesIntroduction from './pages/taxes/introduction/TaxIntroduction';
import TaxVictim from './pages/taxes/victim/TaxVictim';
import TaxScammer from './pages/taxes/scammer/TaxScammer';
import TaxScammerSimplified from './pages/taxes/scammer/TaxScammerSimplified';
import TaxVictimSimplified from './pages/taxes/victim/TaxVictimSimplified';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />

        <Route path="/honeypot/introduction" element={<Introduction />} />
        <Route path="/honeypot/scammer" element={<Scammer />} />
        <Route path="/honeypot/victim" element={<Victim />} />

        <Route path="/rugpull/introduction" element={<RugpullIntroduction />} />
        <Route path="/rugpull/victim" element={<RugpullVictim />} />
        <Route path="/rugpull/scammer" element={<RugpullScammer />} />

        <Route path="/taxes/introduction" element={<TaxesIntroduction />} />
        <Route path="/taxes/victim" element={<TaxVictim />} />
        <Route path="/taxes/scammer" element={<TaxScammer />} />
        <Route
          path="/taxes/scammer_simple"
          element={<TaxScammerSimplified />}
        />
        <Route path="/taxes/victim_simple" element={<TaxVictimSimplified />} />
      </Route>
    </Routes>
  );
}

export default App;
