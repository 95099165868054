import React from 'react';
import {Button} from "react-bootstrap";

function Home() {
    return (<div>
        <h1>ScamFi: Learn to Avoid Crypto Scams</h1>

        <p>
            ScamFi is an interactive learning platform that teaches users how to avoid crypto scams. By connecting your
            MetaMask wallet, you can learn about common scam techniques in a safe and engaging way.
        </p>

        <h2>How it works</h2>

        <ol>
            <li>Connect your MetaMask wallet.</li>
            <li>Connect to BSC-testnet<br/><Button href="https://chainlist.org/chain/97" target="_blank" variant="info">Connect
                to testnet</Button></li>
            <li>Choose a lesson to learn about.</li>
            <li>Follow the instructions to complete the lesson.</li>
        </ol>

        <h2>Topics covered</h2>

        <ul>
            <li>Rug pulls</li>
            <li>Honeypot</li>
            <li>Taxes scam</li>
        </ul>

        <h2>Benefits</h2>

        <ul>
            <li>Learn about common scam techniques in a safe and engaging way.</li>
            <li>Learn how to identify and avoid scams.</li>
            <li>Be better equipped to protect your crypto assets.</li>
        </ul>
    </div>);
}

export default Home;
