import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Sidebar.css';

function Sidebar() {
  const location = useLocation();

  const isRoot = location?.pathname === '/';

  if (isRoot) {
    return null;
  }

  const isHoneypot = location?.pathname?.includes('honeypot');
  const isRugpull = location?.pathname?.includes('rugpull');
  const isTaxes = location?.pathname?.includes('taxes');

  return (
    <div className="d-flex flex-column sidebar-container">
      <ul className="nav flex-column">
        {isHoneypot && (
          <>
            <li className="sidebar-item">
              <NavLink to="/honeypot/introduction">Introduction</NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/honeypot/scammer">Scammer</NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/honeypot/victim">Victim</NavLink>
            </li>
          </>
        )}

        {isRugpull && (
          <>
            <li className="sidebar-item">
              <NavLink to="/rugpull/introduction">Introduction</NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/rugpull/scammer">Scammer</NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/rugpull/victim">Victim</NavLink>
            </li>
          </>
        )}

        {isTaxes && (
          <>
            <li className="sidebar-item">
              <NavLink to="/taxes/introduction">Introduction</NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/taxes/scammer">Scammer(DEX)</NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/taxes/victim">Victim(DEX)</NavLink>
            </li>
            {/*Dima: link below referrer to the unfinished content*/}
            {/*<li className="sidebar-item">*/}
            {/*  <NavLink to="/taxes/scammer_simple">Scammer(Wallet)</NavLink>*/}
            {/*</li>*/}
            <li className="sidebar-item">
              <NavLink to="/taxes/victim_simple">Victim(Wallet)</NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;