import Pancake_buy_1 from './victim/assets/Pancake_buy_1.png';
import Pancake_buy_2 from './victim/assets/Pancake_buy_2.png';
import Pancake_buy_3 from './victim/assets/Pancake_buy_3.png';
import Pancake_buy_4 from './victim/assets/Pancake_buy_4.png';
import Pancake_buy_5 from './victim/assets/Pancake_buy_5.png';
import Pancake_buy_6 from './victim/assets/Pancake_buy_6.png';
import './Victim.css';

export function Victim() {
    return (
        <>
            <div className="mb-4">
                <div className="section-wrapper">

                    The victim receives an address to exchange an unknown token.
                    <br/>
                    The victim is presented with an offer to invest in a "promising"
                    project.
                    <br/>
                    The victim is told that the token is going to skyrocket in value.
                    <br/>
                    The victim is encouraged to buy as much of the token as possible.
                    <br/>
                    <a href="https://pancakeswap.finance/swap?chain=bscTestnet&inputCurrency=0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814&outputCurrency=0x0144c066F1FC6002e51592e21C2855b3aBFED382">
                        https://pancakeswap.finance/swap?chain=bscTestnet&inputCurrency=0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814&outputCurrency=0x0144c066F1FC6002e51592e21C2855b3aBFED382
                    </a>
                    <br/>
                    <p>
                        Users are typically attracted to the opportunity to buy a token
                        immediately. They believe that the more tokens they buy right
                        away, the more they will earn when the price goes up.
                    </p>
                    <p>Example</p>
                    <p>
                        Let's consider an example of a user who is provided with an
                        address for a token.
                    </p>
                    <br/>
                    Example: 0x0144c066F1FC6002e51592e21C2855b3aBFED382
                </div>
                <br/>

            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_1} alt="Pancake_buy_1"/>
                    <br/>
                    1. To buy a "custom" token, you need to select the token you will buy
                    for from the top, and select the token you will buy from the bottom.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_2} alt="Pancake_buy_2"/>
                    <br/>
                    2. Paste the address of the custom token in the search bar.
                </div>
            </div>


            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_3} alt="Pancake_buy_3"/>
                    <br/>
                    3. Enter the amount of tokens you are willing to spend on the custom
                    token.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_4} alt="Pancake_buy_4"/>
                    <br/>
                    4. After entering the required parameters, click "Swap" and confirm the
                    transaction.
                </div>
            </div>


            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_5} alt="Pancake_buy_5" style={{
                        maxWidth: "400px"
                    }}/>
                    <br/>
                    5. When trying to sell a token, you will receive an error.
                </div>
            </div>

            <div className="mb-4">
                <div className="section-wrapper">
                    <img className="section-image" src={Pancake_buy_6} alt="Pancake_buy_6" style={{
                        maxWidth: "500px"
                    }}/>
                    <br/>
                    Honeypot tokens can be bought without any problems, but they cannot be
                    sold because the token contract prohibits sending tokens to the address
                    of the pair smart contract.
                </div>
            </div>

        </>
    );
}

export default Victim;
