import { Button } from 'react-bootstrap';

export default function OpenPancakeSwapButton() {
  const openPancakeSwap = () => {
    window.open('https://pancakeswap.finance/swap?chain=bscTestnet', '_blank');
  };

  return (
    <div>
      <Button
        variant="info"
        onClick={openPancakeSwap}
        style={{ minWidth: '200px' }}
      >
        PancakeSwap
      </Button>
    </div>
  );
}
