import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import './Layout.css';

function Layout() {
  return (
    <div className="layout-container">
      <Header />
      <main className="main-container mt-4">
        <Sidebar />
        <div className="container-fluid mx-4 mb-4">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

export default Layout;
