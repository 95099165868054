import {numberWithCommas} from '../../../utils';

interface DisplayTokenBalanceProps {
    balance?: string;
}


export default function DisplayTokenBalance(props: DisplayTokenBalanceProps) {
    const {balance} = props;

    return (
        <div>
            Token Balance:{' '}
            <b>{balance ? numberWithCommas(balance.substring(0, balance.length - 7)) : 'no balance yet'}</b>
        </div>
    );
}
